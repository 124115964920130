import PolicyView from '../PolicyView';
import styles from './CheckoutSubmit.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import Button from '../Button';
import { PRIORITY } from 'wix-ui-tpa/Button';
import React from 'react';
import { DispatchType, Policy } from '@wix/restaurants-client-logic';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { SetContactlessDeliveryPayload } from '../../../../state/checkout/checkout.actions.types';
import ToggleSwitch from '../ToggleSwitch';
import { Modals } from '../../../../core/constants';
import { useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';

interface CheckoutSubmitProps {
  orderPolicy?: Policy;
  paymentMethod?: string;
  isContactlessDeliveryChecked?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  submitOrder: Function;
  isLoading?: boolean;
  dispatchType: DispatchType;
  setContactlessDelivery: (payload: SetContactlessDeliveryPayload) => void;
  siteIsTemplate?: boolean;
}
export const CheckoutSubmit: React.FC<CheckoutSubmitProps> = ({
  orderPolicy,
  dispatchType,
  paymentMethod,
  isContactlessDeliveryChecked,
  openModal,
  setContactlessDelivery,
  submitOrder,
  isLoading,
  siteIsTemplate,
}) => {
  const { t } = useTranslation();
  const [showCheckboxError, setShowCheckboxError] = React.useState(false);
  const hasCheckbox = Boolean(orderPolicy?.policyCheckbox);
  const checkedByDefault = Boolean(orderPolicy?.policyCheckbox?.checkedByDefault === 'CHECKED_BY_DEFAULT');
  const [isButtonDisabled, setButtonDisabled] = React.useState(hasCheckbox && !checkedByDefault);
  const handlePolicyChange = React.useCallback(() => {
    setButtonDisabled(!isButtonDisabled);
    setShowCheckboxError(false);
  }, [isButtonDisabled]);
  const shouldShowContactlessOption = dispatchType === 'delivery' && paymentMethod !== 'offline';
  const { experiments } = useExperiments();
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const shouldOpenTemplatesModal = siteIsTemplate && templatesDemoExperimentEnabled;
  const isContactlessLabelExperiment = experiments.enabled('specs.restaurants.fix-it-no-contact-label');

  return (
    <div className={styles.wrapper}>
      {orderPolicy && (
        <PolicyView
          className={styles.policies}
          hasCheckbox={hasCheckbox}
          checkedByDefault={!isButtonDisabled}
          termsAndConditions={orderPolicy.termsAndConditions}
          privacyPolicy={orderPolicy.privacyPolicy}
          onChange={handlePolicyChange}
          openModal={openModal}
          showError={showCheckboxError}
        />
      )}
      {shouldShowContactlessOption && (
        <label className={styles.contactless}>
          <ToggleSwitch
            data-hook={dataHooks.checkoutReviewContactlessToggle}
            checked={isContactlessDeliveryChecked}
            onChange={() => {
              setContactlessDelivery({ isContactless: !isContactlessDeliveryChecked });
            }}
          />
          {isContactlessLabelExperiment ? (
            <div className={styles.contactlessLabelWrapper} data-hook={dataHooks.checkoutReviewContactlessToggleLabel}>
              <Text typography="header-xxs" className={styles.contactlessLabelNew}>
                {t('checkout_main_orderreview_nocontact_toggle')}
              </Text>
              <Text typography={'p3-s'}> {t('checkout_main_orderreview_outsidedoor_checkbox')}</Text>
            </div>
          ) : (
            <Text typography="p3-s" className={styles.contactlessLabel}>
              {t('checkout_main_orderreview_outsidedoor_checkbox')}
            </Text>
          )}
        </label>
      )}
      <Button
        upgrade
        onClick={() => {
          if (isButtonDisabled) {
            setShowCheckboxError(true);
          } else {
            shouldOpenTemplatesModal ? openModal({ modal: Modals.TEMPLATES_MODAL }) : submitOrder();
          }
        }}
        data-hook={dataHooks.checkoutReviewSubmitOrder}
        fullWidth
        priority={PRIORITY.primary}
        className={styles.continueButton}
        loading={isLoading}
        disabled={isLoading}
      >
        <Text typography="p2-m-colorless">{t('checkout_main_orderreview_submit_button')}</Text>
      </Button>
    </div>
  );
};
CheckoutSubmit.displayName = 'CheckoutSubmit';

export default CheckoutSubmit;
