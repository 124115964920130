import { DisplayableMenu } from '@wix/restaurants-client-logic';

export const hasItemImages = (displayableMenu: DisplayableMenu) => {
  let hasImages = false;
  displayableMenu.map((displayableSubMenu) => {
    displayableSubMenu.sections.map((displayableSection) => {
      displayableSection.items.map((displayableMenuItem) => {
        if (displayableMenuItem.image) {
          hasImages = true;
        }
      });
    });
  });
  return hasImages;
};
