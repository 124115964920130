import React from 'react';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { Address } from '@wix/restaurants-client-logic';
import { SectionNotification, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/SectionNotification';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import { TextButton } from '../TextButton';
import styles from './SavedAddressView.scss';
import { useTranslation } from 'yoshi-flow-editor-runtime';

export interface SavedAddressViewProps {
  onChange: () => void;
  address: Address;
  error?: string;
}

const SavedAddressView: React.FC<SavedAddressViewProps> = ({ address, error, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.address}>
          <Text tagName="label" typography="p2-s" className={styles.label} data-hook={dataHooks.savedAddressLabel}>
            {t('checkout_main_delivery_address') + ':'}
          </Text>
          {address.label && (
            <Text typography="p2-m" className={styles.line} data-hook={dataHooks.savedAddressLabelField}>
              {address.label}
            </Text>
          )}
          <Text typography="p2-m" className={styles.line} data-hook={dataHooks.savedAddressFormatted}>
            {address.formatted}
          </Text>
          {address.addressLine2 && (
            <Text typography="p2-m" className={styles.line} data-hook={dataHooks.savedAddressLine2}>
              {address.addressLine2}
            </Text>
          )}
          {address.comment && (
            <Text typography="p2-m" className={styles.line} data-hook={dataHooks.savedAddressComment}>
              {address.comment}
            </Text>
          )}
        </div>
        <div className={styles.cta}>
          <TextButton
            data-hook={dataHooks.savedAddressChange}
            priority={TEXT_BUTTON_PRIORITY.primary}
            onClick={onChange}
          >
            {t('Checkout_main_delivery_method_change2_cta')}
          </TextButton>
        </div>
      </div>

      {error && (
        <SectionNotification type="error" className={styles.error} data-hook={dataHooks.savedAddressError}>
          <SectionNotification.Icon icon={<Error />} />
          <SectionNotification.Text>{error}</SectionNotification.Text>
        </SectionNotification>
      )}
    </div>
  );
};

SavedAddressView.displayName = 'SavedAddressView';

export default SavedAddressView;
