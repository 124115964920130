import React, { useEffect, useState } from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import Text from '../../core-components/Text';
import {
  SetCurbsideOutfitInfoPayload,
  SetCurbsidePayload,
  SetDeliveryAddressPayload,
  SetDineInTablePayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
} from '../../../../state/checkout/checkout.actions.types';
import styles from './DispatchSettings.scss';
import RestaurantTakeoutDetails from '../AddressInformation/RestaurantTakeoutDetails';
import CurbsidePickup from '../CurbsidePickup';
import { useBi, useExperiments, useTranslation } from 'yoshi-flow-editor-runtime';
import dataHooks from '../../data-hooks';
import {
  Address,
  CurbsideInfo,
  DispatchInfo,
  getCachedTranslationFunction,
  Restaurant,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import { getDispatchTimeSelectorDetails } from '../DispatchTimeSelector/DispatchTimeSelector.helper';
import DineInDetails from '../DineInDetails/index';
import { replaceSpecialChars } from '../../../../core/logic/replaceSpecialChars';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';

export type TimingOption = 'asap' | 'later';

export interface DispatchSettingsProps {
  onRequestClose: () => void;
  initialDispatchType: VirtualDispatchType;
  initialAddress?: Address;
  initialTimingOption?: TimingOption;
  restaurant: Restaurant;
  supportedDispatchTypes: Set<VirtualDispatchType>;
  supportedDispatchTypesV2: Set<VirtualDispatchType>;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (dispatchType: SetDispatchTimePayload) => void;
  setDineInTable: (payload: SetDineInTablePayload) => void;
  setDeliveryAddress: (address: SetDeliveryAddressPayload) => void;
  setCurbside: (isCurbside: SetCurbsidePayload) => void;
  setCurbsideOutfitInfo: (curbsideOutfitInfo: SetCurbsideOutfitInfoPayload) => void;
  initialCurbsideOutfitInfo?: string;
  initialDispatchTime?: number;
  initialCurbside?: boolean;
  initialTableNumber?: string;
  curbsideInfo?: CurbsideInfo;
  formattedAddressWithComment: string;
  isMobile: boolean;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
  isRTL?: boolean;
  dineInLabel?: string;
}

const DispatchSettings: React.FC<DispatchSettingsProps> = ({
  onRequestClose,
  initialDispatchType,
  initialAddress,
  initialCurbside,
  initialTimingOption,
  restaurant,
  supportedDispatchTypes,
  setDispatchType,
  setDispatchTime,
  setDeliveryAddress,
  setCurbside,
  setCurbsideOutfitInfo,
  initialCurbsideOutfitInfo,
  initialDispatchTime,
  curbsideInfo,
  formattedAddressWithComment,
  isMobile,
  idealDeliveryArea,
  businessNotification,
  isRTL,
  initialTableNumber,
  setDineInTable,
  dineInLabel,
  supportedDispatchTypesV2,
}) => {
  const { t } = useTranslation();
  const biLogger = useBi();
  const { experiments } = useExperiments();
  const [hasErrors, setHasErrors] = useState(false);
  const [isDineInEmptyField, setIsDineInEmptyField] = useState(false);
  const [dispatchType, setType] = useState<VirtualDispatchType>(initialDispatchType);
  const [tableNumber, setTableNumber] = useState(initialTableNumber);
  const [isCurbside, onCurbsideToggle] = useState(initialCurbside);
  const [localCurbsideOutfitInfo, setLocalCurbsideOutfitInfo] = useState(initialCurbsideOutfitInfo);
  const [curbsideOutfitInfoError, setCurbsideOutfitInfoError] = useState(false);
  const [timingOptionPickup, setTimingOptionPickup] = useState(initialTimingOption);
  const [timingOptionDelivery, setTimingOptionDelivery] = useState(initialTimingOption);
  const [selectedDateTimePickup, setSelectedDateTimePickup] = useState(initialDispatchTime || 0);
  const [selectedDateTimeDelivery, setSelectedDateTimeDelivery] = useState(initialDispatchTime || 0);
  const selectedDateTime = dispatchType === 'delivery' ? selectedDateTimeDelivery : selectedDateTimePickup;
  const timingOption = dispatchType === 'delivery' ? timingOptionDelivery : timingOptionPickup;

  const { minOrderPrice, displayableMinOrderPrice } = useMinOrderPriceDetails({
    dispatchType,
    dispatchTime: selectedDateTime,
  });

  const handleOkClick = () => {
    biLogger.dispatchSettingsUpdate({
      dispatchTime: selectedDateTime,
      dispatchTimeOption: timingOption,
      dispatchType,
      curbsidePickupToggle: isCurbside,
      curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
      contactlessDineInInputLabel: dispatchType === 'dine-in' ? dineInLabel : undefined,
      contactlessDineInUOUInput: dispatchType === 'dine-in' ? tableNumber : undefined,
    });
    if (dispatchType === 'dine-in') {
      if (!tableNumber) {
        setIsDineInEmptyField(true);
        setHasErrors(true);
        return;
      }

      setDispatchType({ dispatchType });
      setDineInTable({ tableNumber });
    } else {
      setDispatchType({ dispatchType });

      if (dispatchType === 'delivery' && initialAddress) {
        setDeliveryAddress({ address: initialAddress });
      }

      if (dispatchType === 'takeout') {
        setCurbside({ isCurbside });
        if (curbsideInfo?.additionalInformation && isCurbside) {
          if (!localCurbsideOutfitInfo) {
            biLogger.dispatchSettingsUpdate({
              validationError: 'Outfit Description',
            });
            setCurbsideOutfitInfoError(true);
            return;
          }
          setCurbsideOutfitInfo({ curbsideOutfitInfo: localCurbsideOutfitInfo });
        }
      }
    }

    if (timingOption === 'later') {
      setDispatchTime({ timestamp: selectedDateTime });
    } else {
      setDispatchTime({ timestamp: undefined });
    }

    onRequestClose();
  };

  const onChangeTableNumber = (value: string) => {
    /* Validate that the tableNumber contains only letters and digits */
    const validValue = replaceSpecialChars(value);

    if (validValue !== tableNumber) {
      setIsDineInEmptyField(false);
      setTableNumber(validValue);
    }
  };

  useEffect(() => {
    const { dateError, timeError } = getDispatchTimeSelectorDetails({
      timingOption,
      dispatchTime: selectedDateTime,
      restaurant,
      dispatchType,
      t,
      idealDeliveryArea,
    });

    setHasErrors(Boolean(dateError || timeError || (isDineInEmptyField && dispatchType === 'dine-in')));
  });

  const footer = (
    <ModalLayoutBasicFooter
      isMobile={isMobile}
      onOkClick={handleOkClick}
      onCancelClick={() => {
        biLogger.closeDispatchSettings({ close_type: 'cancel' });
        onRequestClose();
      }}
      isSaveBtnDisabled={hasErrors}
      fullWidthMobile
    />
  );

  const showCurbside = dispatchType === 'takeout' && curbsideInfo;

  const sdt = experiments.enabled('specs.restaurants.olo-client-dtl-v2')
    ? supportedDispatchTypesV2
    : supportedDispatchTypes;

  return (
    <ModalLayoutSelection
      header={t('order_settings_modal_title')}
      footer={footer}
      onCloseClick={() => {
        biLogger.closeDispatchSettings({ close_type: 'exit' });
        onRequestClose();
      }}
      data-hook="dispatch-settings-modal"
      scrollable
    >
      <div className={styles.wrapper} data-hook={dataHooks.dispatchSettingWrapper}>
        {sdt.size > 1 && (
          <DispatchTypeSelector
            className={styles.selector}
            dispatchType={dispatchType}
            supportedDispatchTypes={sdt}
            setDispatchType={(payload: SetDispatchTypePayload) => setType(payload.dispatchType)}
            isMobile={isMobile}
          />
        )}
        {dispatchType === 'takeout' && (
          <React.Fragment>
            <RestaurantTakeoutDetails
              address={restaurant.address}
              formattedAddressWithComment={formattedAddressWithComment}
              className={Boolean(minOrderPrice)}
            />
            {Boolean(minOrderPrice) && (
              <div className={styles.minOrderPrice}>
                <Text data-hook={dataHooks.addressInformationMinOrderPrice} typography="p2-s-secondary">
                  {t('Order_Online_MinimumOrder_Amount_Label', { amount: displayableMinOrderPrice })}
                </Text>
              </div>
            )}
          </React.Fragment>
        )}
        {dispatchType === 'dine-in' ? (
          <DineInDetails
            tableNumber={tableNumber}
            onChangeTableNumber={onChangeTableNumber}
            shouldShowError={isDineInEmptyField}
          />
        ) : (
          <React.Fragment>
            <DispatchTimeSelector
              businessNotification={businessNotification}
              idealDeliveryArea={idealDeliveryArea}
              isMobile={isMobile}
              restaurant={restaurant}
              dispatchType={dispatchType}
              timingOption={timingOption}
              dispatchTime={selectedDateTime}
              onChange={({
                timingOption: newTimingOption,
                selectedDateTime: newSelectedDateTime,
              }: {
                timingOption: TimingOption;
                selectedDateTime?: number;
              }) => {
                if (dispatchType === 'delivery') {
                  setSelectedDateTimeDelivery(newSelectedDateTime || 0);
                  setTimingOptionDelivery(newTimingOption);
                }
                if (dispatchType === 'takeout') {
                  setSelectedDateTimePickup(newSelectedDateTime || 0);
                  setTimingOptionPickup(newTimingOption);
                }
              }}
              t={getCachedTranslationFunction(t)}
              isRTL={isRTL}
              isModal
            />
            {showCurbside && (
              <CurbsidePickup
                setCurbsideOutfitInfo={setLocalCurbsideOutfitInfo}
                curbsideOutfitInfo={localCurbsideOutfitInfo}
                isCurbsideOn={isCurbside}
                onCurbsideToggle={onCurbsideToggle}
                curbsideInfo={curbsideInfo}
                outfitInfoError={curbsideOutfitInfoError}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </ModalLayoutSelection>
  );
};

DispatchSettings.displayName = 'DispatchSettings';
export default DispatchSettings;
