import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { SectionNotification } from 'wix-ui-tpa';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import styles from './CheckoutReview.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import Scroller from '../Scroller';
import { ReactComponent as WarningSignIcon } from '../../../../assets/icons/WarningSign.svg';

export interface CheckoutReviewProps {
  done?: boolean;
  collapsed?: boolean;
  index?: string;
  describedby?: string;
  isMobile: boolean;
  orderPacingLevel?: number;
}

const CheckoutReview: React.FC<CheckoutReviewProps> = ({
  index = '1',
  collapsed,
  done,
  describedby,
  isMobile,
  orderPacingLevel,
}) => {
  const titleId = `${dataHooks.checkoutReview}-titleId`;
  const { t } = useTranslation();

  return (
    <Scroller name="checkout-review" condition={isMobile && !done && !collapsed}>
      <div
        data-hook={dataHooks.checkoutReview}
        className={styles.wrapper}
        aria-describedby={describedby}
        aria-labelledby={titleId}
      >
        <CheckoutFlowStepTitle
          text={t('checkout_main_orderreview_title')}
          index={index}
          collapsed={collapsed}
          done={done}
          titleId={titleId}
        />
        {!collapsed && (
          <>
            <Text typography="p2-m" className={styles.description}>
              {t('checkout_main_orderreview_text')}
            </Text>
            {orderPacingLevel ? (
              <SectionNotification
                className={styles.orderPacingNotification}
                data-hook={dataHooks.checkoutReviewOrdePacingText}
                type="alert"
              >
                <SectionNotification.Icon icon={<WarningSignIcon />} />
                <SectionNotification.Text>
                  {t('checkout_main_order_pacing_ASAP_notification_delivery_pace1')}
                </SectionNotification.Text>
              </SectionNotification>
            ) : null}
          </>
        )}
      </div>
    </Scroller>
  );
};

CheckoutReview.displayName = 'CheckoutReview';

export default CheckoutReview;
