import DispatchSettings, { TimingOption } from './DispatchSettings';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setDeliveryAddress,
  setDispatchTime,
  setDispatchType,
  setCurbside,
  setCurbsideOutfitInfo,
  setDineInTable,
} from '../../../../state/checkout/checkout.actions';
import {
  supportedDispatchTypesSelector,
  supportedDispatchTypesSelectorV2,
} from '../../../../state/selectors/supportedDispatchTypesSelector';
import { formattedAddressWithCommentSelector } from '../../../../state/selectors/formattedAddressWithCommentSelector';
import { deliveryAreaSelectorByDispatchAddress } from '../../../../state/selectors/deliveryAreaSelector';
import { businessNotificationSelector } from '../../../../state/selectors/businessNotificationSelector';
import { getCurbsideInfo } from '@wix/restaurants-client-logic';
import { PickupDispatch } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { getVirtualDispatchTypeFromDispatch, getDineInInfo } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState) {
  const idealDeliveryArea = deliveryAreaSelectorByDispatchAddress(state);
  const businessNotification = businessNotificationSelector(state);
  const curbsideInfo = getCurbsideInfo(state.session.restaurant.deliveryInfos);
  const dineInInfo = getDineInInfo(state.session.restaurant);

  return {
    initialTimingOption: (state.checkout.dispatch.time ? 'later' : 'asap') as TimingOption,
    initialDispatchTime: state.checkout.dispatch.time,
    initialTableNumber: (state.checkout.dispatch as PickupDispatch).contactlessDineIn?.labelValue!,
    initialCurbside: state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.isCurbside : undefined,
    initialDispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    initialAddress: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined,
    availability: state.session.restaurant.openTimes,
    restaurant: state.session.restaurant,
    supportedDispatchTypes: supportedDispatchTypesSelector(state),
    curbsideInfo,
    initialCurbsideOutfitInfo:
      state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.curbsideAdditionalInformation : undefined,
    formattedAddressWithComment: formattedAddressWithCommentSelector(state),
    isMobile: state.platformParams.isMobile,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    businessNotification,
    isRTL: state.platformParams.isRTL,
    dineInLabel: dineInInfo?.label,
    supportedDispatchTypesV2: supportedDispatchTypesSelectorV2(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setDispatchType,
      setDispatchTime,
      setDeliveryAddress,
      setCurbside,
      setCurbsideOutfitInfo,
      setDineInTable,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchSettings);
